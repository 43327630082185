<template>
  <main>
    <div class="container-fluid">
      <div class="row" style="min-height: calc(100vh - 200px);">
        <div class="col-sm align-self-center">
          <div class="col-sm-8 mx-auto py-5">
            <div class="text-center mb-4">
              <h1 class="text-primary mb-2">Connexion à votre espace demandeur d'emploi</h1>
              <!-- <h6 class="text-muted">Je suis une <router-link :to="{ name: 'accounts.login.ent' }" class="badge bg-success text-white">Entreprise</router-link></h6> -->
            </div>
            <div v-if="error" class="alert alert-danger mb-4 text-center">
              <span class="text-danger">Login ou mot de passe incorrecte</span>
            </div>
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <form class="" method="post" accept-charset="utf-8" @submit.prevent="handleSubmit()">
                <ValidationProvider
                  name="email"
                  rules="required|checkUserCanPass:DE"
                  mode="eager"
                  v-slot="{ errors }"
                >
                  <div class="form-floating mb-3">
                    <input name="email" v-model="user.username" type="text" class="form-control" id="floatingInput" placeholder="name@example.com">
                    <label for="floatingInput">Adresse email ou Téléphone</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                </ValidationProvider>
                <div class="form-floating mb-3">
                  <input v-model="user.password" type="password" class="form-control" id="floatingPassword" placeholder="Password">
                  <label for="floatingPassword">Mot de passe</label>
                </div>
                <div class="d-flex justify-content-end mb-4">
                  <!-- <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3">
                    <label class="form-check-label" for="inlineCheckbox3">Se sourvenir</label>
                  </div> -->
                  <router-link class="text-danger" :to="{ name: 'accounts.registration.resetPassword' }">Mot de passe oublié</router-link>
                </div>
                <div class="d-grid gap-2 my-4">
                  <button type="submit" @click.prevent="onSubmit" class="btn btn-primary btn-lg">Connexion</button>
                </div>
              </form>
            </ValidationObserver>

            <h6 class="text-muted text-center">Vous n'avez pas un compte candidat <router-link :to="{ name: 'accounts.register.de' }" class="text-primary">Inscrivez-vous</router-link></h6>
          </div>
        </div>
        <div class="col-sm p-0">
          <div class="h-100 style_bg_img" style="background-image: url('/assets/img/pexels-tima-miroshnichenko-5198239.jpg');">
            <div class="style_bg_img_bloc p-5 h-100 d-flex flex-column justify-content-center text-center">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-sm-8 mx-auto">
                    <h1 class="text-white mb-4 fw-bolder">Trouvez l'emploi de vos rêves</h1>
                    <p class="text-white mb-3">L'ANPE met à votre disposition une multitude d'offres d'emploi crédibles et vérifées dont il a la gestion ou diffusées par ses partenaires.</p>
                    <router-link :to="{ name: 'accounts.register.de' }" class="text-warning">Créer un compte candidat</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>


<script>
import { authMixin } from '../../mixins/auth-mixin'
import { mapActions } from 'vuex'
import { AbilityBuilder, Ability } from '@casl/ability';
import { ABILITY_TOKEN } from '@casl/vue';
import { abilityDefinition } from '../../acsl/define-ability'

export default {
  name: 'PageLogin',
  inject: {
    $ability: { from: ABILITY_TOKEN }
  },
  data () {
    return {
      user: {
        username: '',
        password: ''
      },
      error: false
    }
  },
  watch: {
    user: {
      handler(){
        this.error = false
      },
      deep: true
    }
  },
  mixins: [ authMixin ],
  created(){
    this.forceLogout()
  },
  methods: {
    ...mapActions({
      authenticate: 'auth/login',
      userRole: 'auth/userRole',
    }),
    async isCompleted () {
      const valid = await this.$refs.observer.validate()
      return valid
    },
    async onSubmit () {
      if(!await this.isCompleted) return
      this.loading = true

      try {
        await this.authenticate(this.user)
        this.updateAbility() // Mettre  à jour les privilèges de l'utilisateur actuel
        this.gotoAfterLogin()
        this.loading = false
      }
      catch (error) {
        // })
        if (error) {
          console.error('Authentication error', error)
        }
         this.$toast.error("Login ou mot de passe incorrecte", {
            position: 'bottom-right',
            duration: 5000
          })
        this.loading = false
        this.error = true
      }
    },
    gotoRegisterPage () {
      this.$router.push({ name: 'register' })
    },
    gotoAfterLogin(){
      let redirectTo = this.$route.query.redirect
      if(redirectTo){
        this.$router.push({ path: redirectTo })
      }
      else {
        this.$router.push({ name: `${this.currentUserHome}` })
      }
      window.location.reload()
    },
    updateAbility() {
      const { can, rules } = new AbilityBuilder(Ability);

      abilityDefinition(can)

      this.$ability.update(rules);
    }
  }
}
</script>
